<template>
  <div class="container py-5 px-0 animate__animated animate__fadeIn">
    <h3 class="mb-5 text-center">Acceso desde dispositivo</h3>
    <p class="mb-3 text-muted text-center">Acepta el inicio de sesión desde tu dispositivo.</p>
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
          <i class="fas fa-mobile-alt display-1 text-muted"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPassword',
  data() {
    return {
      password: ''
    }
  },
  methods: {
    nextStep() {
      
    }
  },
}
</script>